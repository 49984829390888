import './sponsors.scss';

const $ = require('jquery');
require('slick-carousel');

$(document).ready(function() {
  setupSponsorsCarousel();
});

function setupSponsorsCarousel() {
  const carouselOptions =
    { slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      infinite: true,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };

  $('.js-sponsors-carousel')
    .randomize()
    .on('init', function () {
      $(this).addClass('visible');
    })
    .slick(carouselOptions);
}

$.fn.randomize = function(selector) {
  let $elems = selector ? $(this).find(selector) : $(this).children();
  let $parents = $elems.parent();

  $parents.each(function() {
    $(this).children(selector).sort(function (childA, childB) {
      return Math.round(Math.random()) - 0.5;
    }.bind(this)).detach().appendTo(this);
  });

  return this;
};
