import 'mburger-webcomponent';

import '../../../vendor/mmenu-9.3.0/mmenu.min.css';
import './mobilemenu.scss';

// import WhBase from '@mod-system/js/compat/base';
import * as whintegration from '@mod-system/js/wh/integration';

import * as dompack from 'dompack';

import $ from 'jquery';
require('../../../vendor/mmenu-9.3.0/mmenu.min.js');

dompack.onDomReady(() => {
  let settings = {
    "offCanvas": {
      use: true,
    },
    "navbar": {
      title: " "
    },
  };

  let $menu = new Mmenu( "#my-menu", settings );

  const api = $menu.API;

  $('#toggle-mobile-menu').click(function() {
    api.open();
  });

  $('body').removeClass('mm-wrapper--position-left');

  if (whintegration.config.obj.hassearchpage === true) {
    $('.mm-search').on('click', '.fa-search', function(){
      location.href = whintegration.config.obj.searchpagelink + '?words=' + $('#mobile-search').val();
    });

    if($('.search__words') !== undefined)
      $('#mobile-search').val($('.search__words').text());
  }
  
});
